<template>
  <div>
    <hotel-list v-model="selected" title="已选酒店：">
      <van-button
        slot="head-left"
        round
        type="primary"
        size="small"
        class="width-200"
        @click="handleConfirm"
      >
        确认
      </van-button>
    </hotel-list>

    <box>
      <van-field
        v-model="username"
        :class="styles.input"
        placeholder="请输入酒店名称"
        @input="handleInput"
      />
      <div :class="styles.tips">请确保酒店已添加至汇智酒店，并且配置到相关价格类型分组中</div>
      <van-list
        ref="refList"
        v-model="loading"
        :finished="finished"
        :finished-text="startLoaded ? '没有更多了' : '输入酒店名称进行搜索'"
        @load="loadMore"
      >
        <van-cell v-for="item in list" :key="item.id" @click="handleSelect(item)">
          <div
            :class="{
              'flex-between-center': true,
              [styles.selected]: selectedKeys.includes(item.id)
            }"
          >
            <span>{{ item.zhName }}</span>
            <van-icon v-if="selectedKeys.includes(item.id)" name="success" />
          </div>
        </van-cell>
      </van-list>
    </box>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { hotelSearch } from '@/api/hotel'
import Box from '../components/box'
import HotelList from '../components/hotel-list'
import styles from './index.module.scss'

// 最大选择限度
const SELECTABLE_MAXIMUM = 3

export default {
  name: 'GroupHotelSearch',
  components: {
    Box,
    HotelList
  },
  data() {
    return {
      styles,
      username: '',
      selected: [],
      list: [],
      loading: false,
      finished: true,
      startLoaded: false,
      keyWord: undefined,
      pagination: {
        pageIndex: 0,
        pageSize: 10,
        total: 0
      }
    }
  },
  computed: {
    selectedKeys() {
      return this.selected.map(e => e.id)
    }
  },
  created() {
    this.selected = this.$pageInteraction.get() || []
    this.$pageInteraction.set([...this.selected])
    this.handleInput = debounce(this.handleInput, 500)
  },
  methods: {
    async loadMore() {
      const { agencyId } = this.$route.query
      const { pageIndex: page, pageSize: size } = this.pagination

      try {
        const res = await hotelSearch({
          pageIndex: page + 1,
          pageSize: size,
          agencyId,
          keyWord: this.keyWord
        })

        const { data, pageIndex, total } = res
        this.list.push(...data)

        Object.assign(this.pagination, {
          pageIndex,
          total
        })
      } catch (err) {
        /** */
      }

      const { pageIndex: newPage, total } = this.pagination

      this.finished = !newPage || newPage * size >= total
      this.loading = false
    },
    handleInput(val) {
      this.keyWord = val
      this.startLoaded = true
      this.list = []
      this.finished = false
      this.loading = true
      this.pagination.pageIndex = 0
      this.loadMore()
    },
    handleConfirm() {
      this.$pageInteraction.set(this.selected)
      this.$router.back()
    },
    handleSelect(item) {
      const index = this.selectedKeys.findIndex(e => e === item.id)

      if (~index) {
        this.selected.splice(index, 1)
      } else {
        if (this.selected.length >= SELECTABLE_MAXIMUM) {
          this.$toast(`不能选择超过${SELECTABLE_MAXIMUM}个`)
          return
        }

        this.selected.push(item)
      }
    }
  }
}
</script>
